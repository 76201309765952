import { Calendar, GlobeHemisphereEast, SignOut, WifiSlash } from '@phosphor-icons/react';
import { Button, ButtonProps, Divider, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { config } from 'services/config';
import { modalManager } from 'services/modalManager';
import { useDistraction } from 'stores/app';
import { useAuthStore } from 'stores/auth';
import { useOnline } from 'stores/network';
import { Drawer, testIds as drawerTestIds } from 'components/Drawer/Drawer';
import { AppInfo, UserInfo, appInfoTestIds, userInfoTestIds } from './components';

const flowApiUrl = config.getApiUrl();

export const testIds = {
  drawer: drawerTestIds,
  userInfo: userInfoTestIds,
  appInfo: appInfoTestIds,
  buttons: {
    support: 'app-menu-support',
    dateFormat: 'app-menu-date-format',
    language: 'app-menu-language',
    logout: 'app-menu-logout',
  },
};

const buttonProps: ButtonProps = {
  variant: 'subtle',
  size: 'md',
  fullWidth: true,
  radius: 0,
  classNames: { inner: 'justify-start' },
};

export const AppMenuDrawer = () => {
  const { t } = useTranslation();
  const { spyClick, spyMount, spyUnmount, spyRef } = useSpy();
  const { logout } = useAuthStore(['logout']);
  const online = useOnline();
  const { distracting: opened, concentrate: close } = useDistraction('app-menu');
  const { distract: openDateFormatSelect } = useDistraction('date-format-menu');
  const { distract: openLanguageSelect } = useDistraction('language-menu');
  // const { distract: openSupport } = useDistraction('support-form');

  const onClickLanguage = () => {
    spyClick(names.SettingsDrawer.SelectLanguage);
    openLanguageSelect();
  };

  const onClickDateFormat = () => {
    spyClick(names.SettingsDrawer.SelectDateFormat);
    openDateFormatSelect();
  };

  const showLogoutModal = () => {
    modalManager.warning({
      icon: SignOut,
      title: t('navbar.logoutModal.title'),
      message: t('navbar.logoutModal.message'),
      labels: {
        cancel: t('common.cancel'),
        confirm: t('navbar.logoutModal.confirm'),
      },
      onOpen: () => spyMount(names.LogoutModal.self),
      onConfirm: () => {
        spyClick(names.LogoutModal.Accept);
        logout();
        window.location.replace(`${flowApiUrl}/users/logout`);
      },
      onCancel: () => spyClick(names.LogoutModal.Cancel),
      onClose: () => spyUnmount(names.LogoutModal.self),
    });
  };

  const showOfflineLogoutModal = () => {
    modalManager.info({
      icon: WifiSlash,
      title: t('navbar.logoutModal.title'),
      message: t('navbar.logoutModal.messageOffline'),
      cancelProps: { display: 'none' },
      labels: {
        confirm: t('common.close'),
        cancel: '',
      },
    });
  };

  // const onClickSupport = () => {
  //   spyClick(names.SettingsDrawer.Support);
  //   openSupport();
  // };

  const onClickLogout = () => {
    spyClick(names.SettingsDrawer.Logout);

    if (online) showLogoutModal();
    else showOfflineLogoutModal();
  };

  const onClose = () => {
    spyClick(names.SettingsDrawer.Close);
    close();
  };

  useSpyOpened(spyRef, names.SettingsDrawer.self, opened);

  return (
    <Drawer opened={opened} height='90vh' onClose={onClose}>
      <Drawer.Header withCloseButton>{t('appMenu.title')}</Drawer.Header>
      <Drawer.Body>
        <UserInfo />
        <Stack px='tiny' gap='zero' align='start'>
          <Button
            {...buttonProps}
            leftSection={<GlobeHemisphereEast />}
            onClick={onClickLanguage}
            data-testid={testIds.buttons.language}
          >
            {t('appMenu.language')}
          </Button>
          <Button
            {...buttonProps}
            leftSection={<Calendar />}
            onClick={onClickDateFormat}
            data-testid={testIds.buttons.dateFormat}
          >
            {t('appMenu.dateFormat')}
          </Button>
          {/* <Button
            {...buttonProps}
            leftSection={<Headset />}
            onClick={onClickSupport}
            data-testid={testIds.buttons.support}
          >
            {t('appMenu.support')}
          </Button> */}
          <Divider w='100%' px='md' />
          <Button
            {...buttonProps}
            color='red'
            leftSection={<SignOut />}
            onClick={onClickLogout}
            data-testid={testIds.buttons.logout}
          >
            {t('appMenu.logout')}
          </Button>
        </Stack>
      </Drawer.Body>
      <Divider />
      <Drawer.Footer>
        <AppInfo />
      </Drawer.Footer>
    </Drawer>
  );
};
