import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlowStore } from 'stores/flow';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { ROUTES } from 'routes/routes.config';
import { modalManager } from 'services/modalManager';
import { ExecutionId } from '@flow/flow-backend-types';

export const useExecutionErrorModal = () => {
  const { t } = useTranslation();
  const { executionError, deleteExecution, updatePendingExecutionUniqueId, setExecutionError } = useFlowStore([
    'executionError',
    'deleteExecution',
    'updatePendingExecutionUniqueId',
    'setExecutionError',
  ]);
  const navigate = useForceNavigate();

  const handleDiscardExecution = async (flowExecutionId: ExecutionId) => {
    await deleteExecution(flowExecutionId);
    setExecutionError(undefined);
    navigate(ROUTES.HOME);
  };

  const showSingleInstanceModal = (flowExecutionId: ExecutionId) => {
    modalManager.info({
      title: t('meta.pre.errors.singleInstanceConflict.title'),
      message: t('meta.pre.errors.singleInstanceConflict.message'),
      labels: {
        confirm: t('meta.pre.errors.singleInstanceConflict.confirm'),
        cancel: '',
      },
      cancelProps: { display: 'none' },
      withCloseButton: false,
      closeOnClickOutside: false,
      onConfirm: () => handleDiscardExecution(flowExecutionId),
    });
  };

  const showDuplicatedUniqueIdModal = (flowExecutionId: ExecutionId, uniqueIdentifier: string) => {
    const newUniqueId = `${t('meta.pre.errors.uniqueIdConflict.newIdPrefix')} ${uniqueIdentifier}`;

    modalManager.info({
      title: t('meta.pre.errors.uniqueIdConflict.title'),
      message: t('meta.pre.errors.uniqueIdConflict.message', { newUniqueId }),
      labels: {
        confirm: t('meta.pre.errors.uniqueIdConflict.confirm'),
        cancel: t('meta.pre.errors.uniqueIdConflict.cancel'),
      },
      withCloseButton: false,
      closeOnClickOutside: false,
      onConfirm: async () => {
        await updatePendingExecutionUniqueId(flowExecutionId, newUniqueId);
        setExecutionError(undefined);
      },
      onCancel: () => handleDiscardExecution(flowExecutionId),
    });
  };

  useEffect(() => {
    if (!executionError) return;
    const { flowExecutionId, uniqueIdentifier, reason } = executionError;
    if (reason === 'duplicatedUniqueIdentifier') showDuplicatedUniqueIdModal(flowExecutionId, uniqueIdentifier);
    else if (reason === 'singleInstance') showSingleInstanceModal(flowExecutionId);
  }, [executionError]);
};
