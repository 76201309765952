import { Avatar, Button, Flex, Modal, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/routes.config';
import { getInitials, noop } from 'utils';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { Execution } from 'stores/flow';
import { useBlockingModalProps } from './ExecutionBlockingModal.hooks';

export const testIds = {
  content: 'blocked-modal-content',
  title: 'blocked-modal-title',
  user: 'blocked-modal-user',
  icon: 'blocked-modal-icon',
  text: 'blocked-modal-text',
  userAvatar: 'blocked-modal-avatar',
  exitBtn: 'blocked-modal-exit',
};

interface ExecutionBlockedModalProps {
  execution: Execution;
}

export const ExecutionBlockedModal = ({ execution }: ExecutionBlockedModalProps) => {
  const { t } = useTranslation();
  const navigate = useForceNavigate();
  const modalProps = useBlockingModalProps(execution);
  const opened = !!modalProps;

  const { Icon, name, title, text, warning } = modalProps || {};

  return (
    <Modal
      opened={opened}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={480}
      onClose={noop}
    >
      <Flex className='flex-1' mb='md' gap='lg' direction='column' align='center' data-testid={testIds.content}>
        {Icon && (
          <Icon
            size={80}
            strokeWidth={1}
            color={warning ? 'var(--mantine-color-red-4)' : undefined}
            data-testid={testIds.icon}
          />
        )}
        <Flex align='center' direction='column' gap='xs'>
          <Title fw={600} size={20} data-testid={testIds.title}>
            {title}
          </Title>
          {name && (
            <Flex align='center' gap='xs'>
              <Avatar variant='filled' color='teal.6' size='30' data-testid={testIds.userAvatar}>
                {getInitials(name)}
              </Avatar>
              <Text size='xl' data-testid={testIds.user}>
                {name}
              </Text>
            </Flex>
          )}
        </Flex>
        <Text ta='center' data-testid={testIds.text}>
          {text}
        </Text>
        <Button size='md' fullWidth onClick={() => navigate(ROUTES.HOME)} data-testid={testIds.exitBtn}>
          {t('inspection.executionBlockedModal.exit')}
        </Button>
      </Flex>
    </Modal>
  );
};
