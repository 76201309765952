import { useCallback, useState } from 'react';
import { CEBridge, ContainerId, ReportedValue, UiEventType } from '@flow/flow-backend-types';
import { useContainerStore } from 'stores/container';
import { useMantineTheme } from '@mantine/core';
import {
  getCEBridges,
  getContainerStaticEvents,
  isEventValid,
  isUiEventStatic,
  useGetUiEvent,
  useUiEventStore,
} from 'stores/uiEvent';
import { useExecution, useFlowByExecutionId } from 'stores/flow';
import { useInspectionMetaStore } from 'stores/inspectionMeta';
import { getLastReportedValues, getReportCollectionKey, useReportStore } from 'stores/report';
import {
  aggregateImageData,
  getSingleImageData,
  resolveImageData,
} from 'contexts/ItemPhotoContext/ItemPhotoContext.utils';
import { logger } from 'services/logger';
import { formatTime } from 'utils';
import { useUserDateFormat } from 'stores/settings';
import dayjs from 'dayjs';
import { logoLoop } from 'assets';
import { useExecutionTime } from './useExecutionTime';
import { PdfReportContainer } from '../utils/pdf.builder.types';

const EMPTY_SYMBOL = '-';
const TEXT_SIZES = {
  title: 24,
  subtitle: 16,
  regular: 12,
};

const getPdfBuilder = async () => {
  const { PDFBuilder } = await import('../utils/pdf.builder');
  return PDFBuilder;
};

type PDFBuilderType = InstanceType<Awaited<ReturnType<typeof getPdfBuilder>>>;

export function usePDFGenerator(executionId: string) {
  const theme = useMantineTheme();
  const execution = useExecution(executionId);
  const flow = useFlowByExecutionId(executionId);
  const { uiEvents, validations } = useUiEventStore(['uiEvents', 'validations']);
  const getUiEvent = useGetUiEvent();
  const { startTime, endTime, durationTime } = useExecutionTime(executionId);
  const dateFormat = useUserDateFormat();
  const { loadInspectionMeta } = useInspectionMetaStore(['loadInspectionMeta']);
  const { rootContainerIds, containers, containerEventsMap, containerTemplatesMap } = useContainerStore([
    'rootContainerIds',
    'containers',
    'containerEventsMap',
    'containerTemplatesMap',
  ]);
  const { reports } = useReportStore(['reports']);
  const [loading, setLoading] = useState(false);

  const formatEventValues = useCallback(
    (values: ReportedValue[], eventType: UiEventType) => {
      const [value] = values;

      if (value === undefined) return [EMPTY_SYMBOL];

      switch (eventType) {
        case 'DateEvent': {
          const date = new Date(Number(value));
          const formattedDate = dayjs(date).format(dateFormat);

          return [formattedDate];
        }
        case 'TimeOfDayEvent': {
          return [formatTime(Number(value))];
        }
        default: {
          return values;
        }
      }
    },
    [formatTime, dateFormat],
  );

  const getGeneralMeta = useCallback(() => {
    if (!execution) return [];
    const inspectors = execution.joinedUsers
      .map(({ givenName, familyName }) => `${givenName} ${familyName}`)
      .join(', ');

    return [
      ['Inspectors: ', inspectors],
      ['Start Time: ', startTime],
      ['End Time: ', endTime],
      ['Duration Time: ', durationTime],
    ];
  }, [execution]);

  const getExecutionMeta = useCallback(async () => {
    if (!flow || !execution) return [];

    const inspectionMeta = await loadInspectionMeta(flow.id, flow.activeVersion);
    const metadata =
      inspectionMeta?.preInspectionFields?.map((field) => {
        const rawValue = execution.preInspectionMetadata?.find(({ id }) => id === field.id)?.value ?? EMPTY_SYMBOL;

        const transformedValue = (() => {
          try {
            const parsedValue = JSON.parse(rawValue);
            return Array.isArray(parsedValue) ? parsedValue.join(', ') : parsedValue;
          } catch {
            return rawValue;
          }
        })();

        return [`${field.title}: `, transformedValue || EMPTY_SYMBOL];
      }) ?? [];

    return metadata;
  }, [flow, execution]);

  const getContainerImageData = useCallback(
    async (containerId: ContainerId, ceBridges: CEBridge[]) => {
      const { imageEventId } = getContainerStaticEvents(ceBridges, uiEvents);
      const key = getReportCollectionKey(containerId, imageEventId!);
      const imageReports = reports[key];

      if (imageReports) {
        const imageDataMap = aggregateImageData(imageReports);
        const imageDataBase64 = await Promise.all(
          Array.from(imageDataMap.values()).map(async (image) => {
            const imageId = image.thumbnail || image.original;
            const { base64 } = await getSingleImageData(imageId, executionId, containerId);

            return base64;
          }, []),
        );

        return imageDataBase64;
      }

      return [];
    },
    [containers, containerEventsMap, containerTemplatesMap, reports, getCEBridges, resolveImageData],
  );

  const getContainerWithEvents = useCallback(
    async (containerId: ContainerId) => {
      const container = containers[containerId];
      const ceBridges = getCEBridges(container, containerEventsMap, containerTemplatesMap);
      const readableBridges = ceBridges.filter(({ uiEventId }) => !isUiEventStatic(getUiEvent(uiEventId)));
      const imageData = await getContainerImageData(containerId, ceBridges);
      const events = readableBridges.map((ceBridge) => {
        const uiEvent = getUiEvent(ceBridge.uiEventId);
        const values = getLastReportedValues(containerId, uiEvent, reports);
        const formattedValues = formatEventValues(values, uiEvent.type);
        const validation = validations[ceBridge.validationIds[0] ?? ''];

        return {
          id: ceBridge.id,
          isChild: ceBridge.isChild,
          parentId: ceBridge.parentId ?? undefined,
          childrenIds: ceBridge.childrenIds,
          title: ceBridge.isChild ? ` • ${uiEvent.title}` : uiEvent.title,
          values: formattedValues.length ? formattedValues : [EMPTY_SYMBOL],
          isValid: isEventValid(uiEvent, containerId, reports, validation),
        };
      });

      return {
        title: container.title,
        events,
        childrenIds: container.childrenIds,
        imageData,
        order: container.order,
      };
    },
    [
      containers,
      containerEventsMap,
      containerTemplatesMap,
      reports,
      validations,
      getCEBridges,
      isUiEventStatic,
      getUiEvent,
    ],
  );

  const drawContainerTables = useCallback(
    async (pdf: PDFBuilderType, containerIds: ContainerId[], level = 0) => {
      const containersWithEvents = await Promise.all(containerIds.map(getContainerWithEvents));

      containersWithEvents.sort((a, b) => a.order - b.order);

      for (const containerWithEvents of containersWithEvents) {
        let containerType: PdfReportContainer;
        if (level === 0) {
          containerType = 'ancestor';
        } else if (level === 1) {
          containerType = 'intermediate';
        } else {
          containerType = 'descendant';
        }

        const processedEvents = new Set();

        // Iterate and reorder children to appear right after their parents
        for (let j = 0; j < containerWithEvents.events.length; j++) {
          const event = containerWithEvents.events[j];

          if (event.childrenIds?.length > 0 && !processedEvents.has(event.id)) {
            for (const childId of event.childrenIds) {
              const childIndex = containerWithEvents.events.findIndex((e) => e.id.includes(childId));

              if (childIndex > -1) {
                // Move to after the parent event
                const [childEvent] = containerWithEvents.events.splice(childIndex, 1);
                containerWithEvents.events.splice(j, 0, childEvent);
                processedEvents.add(childEvent.id);
              }
            }
          }
        }

        pdf.addContainerTable(containerWithEvents, containerType);

        if (containerWithEvents.childrenIds.length > 0) {
          await drawContainerTables(pdf, containerWithEvents.childrenIds, level + 1);
        } else {
          pdf.addIndent(5);
        }
      }
    },
    [getContainerWithEvents],
  );

  const addTextNoData = useCallback((pdf: PDFBuilderType) => {
    pdf.addText('No data was provided for this form', TEXT_SIZES.regular, 'regular', 1, 0);
  }, []);

  const fetchImageAsBase64 = async (imageUrl: string): Promise<string> => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result); // cast to string if it's Base64
        } else {
          reject(new Error('Failed to convert image to Base64 string.'));
        }
      };
      reader.onerror = () => reject(new Error('FileReader failed.'));
      reader.readAsDataURL(blob);
    });
  };

  const generatePDFPreview = async () => {
    if (!flow || !execution) return null;
    setLoading(true);
    const PDFBuilder = await getPdfBuilder();
    try {
      const pdf = new PDFBuilder(theme);

      const logoBase64 = await fetchImageAsBase64(logoLoop);
      pdf.addLogo(logoBase64);

      const generalMetadata = getGeneralMeta();
      const executionMetadata = await getExecutionMeta();

      pdf.addText(flow.name, TEXT_SIZES.title, 'bold', 0, 0, 'left');
      pdf.addTextTable({ body: generalMetadata, tableWidth: 'auto' }, 5);
      pdf.addSeparatorLine(0, -5);

      pdf.addText('Details', TEXT_SIZES.subtitle, 'semibold', 1, 0);
      if (executionMetadata.length === 0) {
        addTextNoData(pdf);
      } else {
        pdf.addTextTable({ body: executionMetadata });
      }
      pdf.addSeparatorLine(0, 0);

      if (rootContainerIds.length > 0) {
        pdf.addIndent(5);
        await drawContainerTables(pdf, rootContainerIds);
      } else {
        addTextNoData(pdf);
      }
      pdf.addIndent(5);

      return pdf.doc;
    } catch (error) {
      console.error(`Error generating pdf: ${error}`);
      logger.error(`Error generating pdf: ${error}`);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { generate: generatePDFPreview, loading };
}
