import { Stack, Title, StackProps, ThemeIcon } from '@mantine/core';
import { ClipboardText } from '@phosphor-icons/react';
import { ReactNode } from 'react';

interface EmptyStateProps extends StackProps {
  hidden: boolean;
  title?: string;
  icon?: ReactNode;
  children?: string | ReactNode;
}

export const testIds = {
  container: 'empty-state-container',
  defaultIcon: 'empty-state-defaultIcon',
  title: 'empty-state-title',
};

export const EmptyStateV2 = ({ title, icon, hidden, children, ...props }: EmptyStateProps) => {
  if (hidden) return null;
  return (
    <Stack align='center' gap='tn' py='sm' data-testid={testIds.container} {...props}>
      {icon ?? (
        <ThemeIcon variant='subtle' size={48} color='cool.4' data-testid={testIds.defaultIcon}>
          <ClipboardText size={48} weight='duotone' />
        </ThemeIcon>
      )}
      {title && (
        <Title order={6} c='cool.9' data-testid={testIds.title}>
          {title}
        </Title>
      )}
      {children}
    </Stack>
  );
};
