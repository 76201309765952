import { useTranslation } from 'react-i18next';
import { SmileyNervous, SmileyMeh, Smiley } from '@phosphor-icons/react';
import { Button, Rating, Stack, Textarea, ThemeIcon } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { Drawer } from 'components';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { useFlowStore } from 'stores/flow';

export const testIds = {
  skipBtn: 'survey-drawer-skip',
  rating: 'survey-drawer-rating',
  getSelectedTestId: (value: number) => `survey-rating-${value}-selected`,
  getUnselectedTestId: (value: number) => `survey--rating-${value}-unselected`,
  comment: 'survey-drawer-comment',
  submitBtn: 'survey-drawer-submit',
};

const COMMENT_MAX_LENGTH = 2000;
const ICON_SIZE = 70;
const SELECTED_ICON_COLORS: Record<number, string> = {
  1: 'red.4',
  2: 'amber.4',
  3: 'emerald.6',
};

const getIconProps = (selected: boolean, rating: number) => ({
  size: ICON_SIZE,
  color: selected ? SELECTED_ICON_COLORS[rating] : 'cool.4',
});

const getIcon = (selected: boolean) => (value: number) => {
  switch (value) {
    case 1:
      return (
        <ThemeIcon variant='transparent' {...getIconProps(selected, 1)}>
          <SmileyNervous
            fill='currentColor'
            weight='bold'
            size={ICON_SIZE}
            data-testid={selected ? testIds.getSelectedTestId(1) : testIds.getUnselectedTestId(1)}
          />
        </ThemeIcon>
      );
    case 2:
      return (
        <ThemeIcon variant='transparent' {...getIconProps(selected, 2)}>
          <SmileyMeh
            fill='currentColor'
            weight='bold'
            size={ICON_SIZE}
            data-testid={selected ? testIds.getSelectedTestId(2) : testIds.getUnselectedTestId(2)}
          />
        </ThemeIcon>
      );
    case 3:
      return (
        <ThemeIcon variant='transparent' {...getIconProps(selected, 3)}>
          <Smiley
            fill='currentColor'
            weight='bold'
            size={ICON_SIZE}
            data-testid={selected ? testIds.getSelectedTestId(3) : testIds.getUnselectedTestId(3)}
          />
        </ThemeIcon>
      );
    default:
      return null;
  }
};

export const SurveyDrawer = () => {
  const { t } = useTranslation();
  const { spyRef, spyClick } = useSpy();
  const { executionIdPendingRating, setExecutionIdPendingRating } = useFlowStore([
    'executionIdPendingRating',
    'setExecutionIdPendingRating',
  ]);
  const [{ rating, comment }, setRatingState] = useSetState({ rating: 0, comment: '' });
  const opened = !!executionIdPendingRating;

  useSpyOpened(spyRef, names.Rating.self, opened);

  const onSkipClick = () => {
    spyClick(names.Rating.Skip);
    setExecutionIdPendingRating(undefined);
  };

  const onChangeRating = (value: number) => {
    setRatingState((prev) => ({ rating: value === prev.rating ? 0 : value }));
  };

  const onChangeComment = (value: string) => {
    setRatingState({ comment: value });
  };

  const onClickSubmit = () => {
    const value = {
      executionId: executionIdPendingRating,
      rating,
      ...(comment ? { comment } : {}),
    };

    spyClick(names.Rating.Submit, value);
    setExecutionIdPendingRating(undefined);
  };

  return (
    <Drawer height={370} opened={opened} onClose={onSkipClick}>
      <Drawer.Header
        rightSection={
          <Button
            variant='subtle'
            size='compact-md'
            h='100%'
            py='sm'
            px='xs'
            bg='transparent'
            color='emerald.6'
            onClick={onSkipClick}
            data-testid={testIds.skipBtn}
          >
            {t('survey.skipBtn')}
          </Button>
        }
      >
        {t('survey.title')}
      </Drawer.Header>
      <Drawer.Body>
        <Stack h='100%'>
          <Rating
            className='m-auto gap-6 mt-3 mb-5'
            value={rating}
            count={3}
            emptySymbol={getIcon(false)}
            fullSymbol={getIcon(true)}
            highlightSelectedOnly
            onChange={onChangeRating}
            data-testid={testIds.rating}
          />
          <Textarea
            flex={1}
            classNames={{
              wrapper: 'h-full',
              input: 'h-full',
            }}
            maxLength={COMMENT_MAX_LENGTH}
            value={comment}
            placeholder={t('survey.inputPlaceholder')}
            onChange={(e) => onChangeComment(e.currentTarget.value)}
            data-testid={testIds.comment}
          />
        </Stack>
      </Drawer.Body>
      <Drawer.Footer>
        <Button size='lg' disabled={!rating} onClick={onClickSubmit} data-testid={testIds.submitBtn}>
          {t('survey.submitBtn')}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};
