import { Box } from '@mantine/core';
import { PageProvider } from 'providers/page.provider';
import { names, useSpy, useSpyMount } from 'services/espionage';
import { themeV2 } from 'assets';
import {
  AppMenuDrawer,
  appMenuDrawerTestIds,
  DateFormatSelectDrawer,
  LanguageSelectDrawer,
  SupportDrawer,
} from 'components';
import {
  AnimationStage,
  headerTestIds,
  HomePageHeader,
  HomePageLoader,
  HomePageLoadProvider,
  HomePageTabs,
  tabsTestIds,
  useHomePageLoad,
  SurveyDrawer,
} from './components';

export const testIds = {
  header: headerTestIds,
  tabs: tabsTestIds,
  menu: appMenuDrawerTestIds,
};

export const HomePageInner = () => {
  const { spyRef } = useSpy();
  const { animationStage, finishAnimationStage } = useHomePageLoad(AnimationStage.background);

  useSpyMount(spyRef, names.HomePage.self);

  return (
    <PageProvider theme={themeV2}>
      <Box
        pos='relative'
        w='100%'
        h='100%'
        bg={animationStage < AnimationStage.background ? 'emerald.6' : undefined}
        className='transition-colors overflow-y-hidden'
        onTransitionEnd={finishAnimationStage}
      >
        <HomePageLoader />
        <HomePageHeader />
        <HomePageTabs />
      </Box>
      <AppMenuDrawer />
      <LanguageSelectDrawer />
      <DateFormatSelectDrawer />
      <SupportDrawer />
      <SurveyDrawer />
    </PageProvider>
  );
};

export const HomePage = () => (
  <HomePageLoadProvider>
    <HomePageInner />
  </HomePageLoadProvider>
);
