import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAndroid } from 'react-device-detect';
import { ArrowLeft, CheckCircle } from '@phosphor-icons/react';
import { Button, Divider, Flex, Stack, Title, Text, ActionIcon, ThemeIcon } from '@mantine/core';
import { ExecutionRouteParams, ROUTES } from 'routes/routes.config';
import { Drawer, UserAvatar, PdfPreviewDrawer } from 'components';
import { useDistraction } from 'stores/app';
import { useExecution, useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { useForceNavigate } from 'hooks';
import { openPdfInNewTab } from 'utils';
import { useOnline } from 'stores/network';
import { modalManager } from 'services/modalManager';
import { useTimeout } from '@mantine/hooks';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { useParams } from 'react-router-dom';
import { toaster } from 'services/toaster';
import { useExecutionTime } from '../../hooks/useExecutionTime';
import { usePDFGenerator } from '../../hooks/usePDFGenerator';

export const testIds = {
  title: 'review-drawer-title',
  back: 'review-drawer-back',
  inspectors: 'review-drawer-inspectors-label',
  startTimeLabel: 'review-drawer-start-label',
  startTime: 'review-drawer-start-time',
  endTimeLabel: 'review-drawer-end-time-label',
  endTime: 'review-drawer-end-time',
  durationTimeLabel: 'review-drawer-duration-time-label',
  durationTime: 'review-drawer-duration-time',
  submitBtn: 'review-drawer-submit',
  openPreviewBtn: 'review-drawer-preview',
};

const USERS_TO_DISPLAY = 3;
const PDF_FILE_EXTENSION = 'PDF';
export const SUCCESS_NAVIGATION_TIMEOUT = 3000;

export const ReviewDrawer = () => {
  const { t } = useTranslation();
  const navigate = useForceNavigate();
  const online = useOnline();
  const { spyRef, spyClick } = useSpy();
  const { distracting: opened, concentrate: close, distract: open } = useDistraction('review-drawer');
  const { executionId } = useParams() as ExecutionRouteParams;
  const { continueExecution, finishExecution, setExecutionIdPendingRating } = useFlowStore([
    'continueExecution',
    'finishExecution',
    'setExecutionIdPendingRating',
  ]);
  const execution = useExecution(executionId);
  const flow = useFlowByExecutionId(executionId);
  const { startTime, endTime, durationTime } = useExecutionTime(executionId);
  const [isSubmitting, setSubmitting] = useState(false);
  const pdfBlobRef = useRef<Blob | null>(null);
  const pdfBase64Ref = useRef<string | null>(null);
  const { generate: generatePDFPreview, loading: isGeneratingPDF } = usePDFGenerator(executionId);
  const [pdfUrl, setPdfUrl] = useState('');

  useSpyOpened(spyRef, names.Review.self, opened);

  const generatedReportName = useMemo(() => {
    const timeStamp = dayjs().format('YYYY-MM-DD hh:mm:ss');
    return `${flow?.name}-${timeStamp}.${PDF_FILE_EXTENSION}`;
  }, [flow]);

  const onAfterSubmit = () => {
    setExecutionIdPendingRating(executionId);
    close();
    navigate(ROUTES.HOME);
  };

  const { start: startNavigationTimeout } = useTimeout(onAfterSubmit, SUCCESS_NAVIGATION_TIMEOUT);

  const preparePDF = async () => {
    if (pdfBlobRef.current) return;
    const pdf = await generatePDFPreview();
    if (!pdf) return;
    pdfBlobRef.current = pdf.output('blob');
    pdfBase64Ref.current = pdf.output('datauristring').split(',')[1] || null;
    if (!pdfBlobRef.current || !pdfBase64Ref.current) showReportFailure();
  };

  const showReportFailure = () => {
    toaster.error({
      title: t('inspection.errors.noReportData'),
      message: '',
    });
  };

  const onSuccess = () => {
    modalManager.custom({
      children: (
        <Flex direction='column' align='center' justify='center' p='md'>
          <ThemeIcon variant='transparent' size={84} color='emerald.7' mb='sm'>
            <CheckCircle size={84} weight='duotone' />
          </ThemeIcon>
          <Title className='text-center' fz='lg' fw={600} c='cool.7'>
            {t('review.successModalTitle')}
          </Title>
        </Flex>
      ),
      centered: true,
      withCloseButton: false,
      radius: 8,
      size: 240,
      onClose: onAfterSubmit,
    });
    startNavigationTimeout();
  };

  const finishInspection = async () => {
    setSubmitting(true);
    await preparePDF();
    const response = await finishExecution(executionId, generatedReportName, pdfBase64Ref.current || '');
    if (response) onSuccess();
    setSubmitting(false);
  };

  const onClickSubmit = async () => {
    spyClick(names.Inspection.Submit);
    if (online) {
      finishInspection();
    } else {
      modalManager.info({
        title: t('inspection.offlineSubmitModal.title'),
        message: t('inspection.offlineSubmitModal.message'),
        labels: { confirm: t('common.confirm'), cancel: '' },
        cancelProps: { display: 'none' },
        onConfirm: finishInspection,
      });
    }
  };

  const onDrawerClose = async () => {
    await continueExecution(executionId);
    pdfBlobRef.current = null;
    pdfBase64Ref.current = null;
    spyClick(names.Review.Back);
    navigate(ROUTES.INSPECTION(executionId));
  };

  const onOpenPreviewClick = async () => {
    spyClick(names.Review.Preview);

    await preparePDF();
    if (!pdfBlobRef.current) return;

    const url = URL.createObjectURL(pdfBlobRef.current);

    if (isAndroid) {
      setPdfUrl(url);
      close();
    } else {
      openPdfInNewTab(url, generatedReportName);
    }
  };

  const onPdfViewerBack = () => {
    setPdfUrl('');
    open();
  };

  return (
    <>
      <PdfPreviewDrawer url={pdfUrl} filename={generatedReportName} onBack={onPdfViewerBack} />
      <Drawer height='80%' opened={opened} onClose={onDrawerClose}>
        <Drawer.Header
          leftSection={
            <ActionIcon size='xl' color='cool.7' variant='subtle' onClick={onDrawerClose} data-testid={testIds.back}>
              <ArrowLeft size={28} />
            </ActionIcon>
          }
        >
          {t('review.title')}
        </Drawer.Header>
        <Drawer.Body>
          <Stack gap='xs'>
            <Title className='break-word' order={4} c='cool.8' data-testid={testIds.title}>
              {flow?.name}
            </Title>
            <Flex align='end' justify='space-between'>
              <Text fz='xl' data-testid={testIds.inspectors}>
                {t('review.inspectors')}
              </Text>
              <UserAvatar.Group users={execution?.joinedUsers ?? []} limit={USERS_TO_DISPLAY} />
            </Flex>
            <Divider />
            <Flex justify='space-between'>
              <Text fz='xl' data-testid={testIds.startTimeLabel}>
                {t('review.startTime')}
              </Text>
              <Text fz='xl' data-testid={testIds.startTime}>
                {startTime}
              </Text>
            </Flex>
            <Divider />
            <Flex justify='space-between'>
              <Text fz='xl' data-testid={testIds.endTimeLabel}>
                {t('review.endTime')}
              </Text>
              <Text fz='xl' data-testid={testIds.endTime}>
                {endTime}
              </Text>
            </Flex>
            <Divider />
            <Flex justify='space-between'>
              <Text fz='xl' data-testid={testIds.durationTimeLabel}>
                {t('review.durationTime')}
              </Text>
              <Text fz='xl' data-testid={testIds.durationTime}>
                {durationTime}
              </Text>
            </Flex>
            <Divider />
          </Stack>
        </Drawer.Body>
        <Drawer.Footer>
          <Button
            variant='outline'
            size='lg'
            disabled={isSubmitting}
            loading={isGeneratingPDF && !isSubmitting}
            onClick={onOpenPreviewClick}
            data-testid={testIds.openPreviewBtn}
          >
            {t('review.openPreviewBtn')}
          </Button>
          <Button
            size='lg'
            disabled={isGeneratingPDF && !isSubmitting}
            loading={isSubmitting}
            onClick={onClickSubmit}
            data-testid={testIds.submitBtn}
          >
            {t('review.submitBtn')}
          </Button>
        </Drawer.Footer>
      </Drawer>
    </>
  );
};
