import { db } from 'services/db';
import { inspectionMetaStore } from 'stores/inspectionMeta';
import { reportApi } from 'stores/report/report.api';
import { Execution } from 'stores/flow';
import { ExecutionId, FailedAction, Flow, PendingAction, StartExecutionRequest } from '@flow/flow-backend-types';
import { arrayToMap } from 'utils';
import { appStore } from './app.store';
import { FailedActionWithReason } from './app.types';

async function getPendingExecutions() {
  const pendingActions = await db.listPendingActions();
  return pendingActions
    .filter((action) => action.type === 'startExecution')
    .map((action) => action.payload as StartExecutionRequest);
}

/**
 * Given a list of flows from the server, remove any outdated flows from the cache.
 * Then, remove any related executions from the cache.
 */
export async function invalidateFlowsCache(serverFlows: Flow[]) {
  try {
    const cachedFlows = await db.getFlows();
    const serverFlowIds = new Set(serverFlows.map((flow) => flow.id));
    const outdatedFlowIds = new Set(cachedFlows.filter((flow) => !serverFlowIds.has(flow.id)).map((flow) => flow.id));
    if (outdatedFlowIds.size > 0) await db.deleteFlowData([...outdatedFlowIds]);

    const cachedExecutions = await db.getExecutions();
    const outdatedExecutionIds = cachedExecutions
      .filter(({ flowRef }) => outdatedFlowIds.has(flowRef.id))
      .map((execution) => execution.id);

    if (outdatedExecutionIds.length > 0) await db.deleteExecutionData(outdatedExecutionIds);
  } catch (error) {
    console.error('Error invalidating cache:', error);
  }
}

/**
 * Given a list of executions from the server, remove any outdated executions from the cache.
 */
export async function invalidateExecutionsCache(serverExecutions: Execution[], currentExecutionId?: ExecutionId) {
  try {
    const [cachedExecutions, pendingExecutions] = await Promise.all([db.getExecutions(), getPendingExecutions()]);
    const serverExecutionIds = new Set(serverExecutions.map((execution) => execution.id));
    const pendingExecutionIds = new Set(pendingExecutions.map((execution) => execution.id));

    // Identify outdated executions in cache
    const outdatedExecutionIds = cachedExecutions
      .filter(({ id }) => {
        const existsOnServer = serverExecutionIds.has(id);
        const createdOffline = pendingExecutionIds.has(id);
        const currentlyWorkedOn = id === currentExecutionId;
        return !currentlyWorkedOn && !createdOffline && !existsOnServer;
      })
      .map((execution) => execution.id);
    // Remove outdated executions and their related data
    if (outdatedExecutionIds.length > 0) await db.deleteExecutionData(outdatedExecutionIds);
  } catch (error) {
    console.error('Error invalidating cache:', error);
  }
}

export async function updateFlowsCache(serverFlows: Flow[]) {
  await db.flows.bulkPut(serverFlows);
}

export async function updateExecutionsCache(serverExecutions: Execution[]) {
  await db.executions.bulkPut(serverExecutions);
}

export async function updateMetadataAndRenderModel(idsAndVersions: string[]) {
  const promises = idsAndVersions.map(async (flowIdAndVersion) => {
    const [id, version, flowExecutionId] = flowIdAndVersion.split(':');

    const [cachedFlowMetadata, cachedRenderModel] = await Promise.all([
      db.flowMetadata.get({ id, version }),
      db.renderModels.get({ flowId: id, version }),
    ]);

    const updateCachePromises: Promise<string | void>[] = [];

    if (!cachedFlowMetadata) {
      updateCachePromises.push(inspectionMetaStore.getState().updateCache(id, version));
    }

    if (!cachedRenderModel) {
      updateCachePromises.push(appStore.getState().updateCache(id, version, flowExecutionId));
    }

    return Promise.all(updateCachePromises);
  });

  await Promise.all(promises);
}

export async function updateLastReports(executions: Execution[]) {
  await Promise.all(
    executions.map(({ id }) =>
      reportApi.getExecutionReports(id).then((reports) => {
        if (reports.length) db.reportedEvents.bulkPut(reports);
      }),
    ),
  );
}

export const categorizePendingActions = (pendingActions: PendingAction[], failedActions: FailedAction[]) => {
  const failedMap = arrayToMap(failedActions, 'id');
  const failedActionsWithReason: FailedActionWithReason[] = [];
  const actionIdsToClear = [];

  for (const action of pendingActions) {
    const failedAction = failedMap[action.id];
    if (failedAction) failedActionsWithReason.push({ action, reason: failedAction });
    else actionIdsToClear.push(action.id);
  }

  return { failedActionsWithReason, actionIdsToClear };
};
