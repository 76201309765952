import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTimePlugin from 'dayjs/plugin/relativeTime'; // dependent on utc plugin
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { TimeTurner } from '@aiola/frontend';
import { DateFormat } from 'interfaces';
import i18n from 'services/i18n';
import { exists } from 'utils';

import 'dayjs/locale/en';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/id';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.locale(i18n.language);

const MORNING_RANGE = 12;
const AFTERNOON_RANGE = 18;
const turner = new TimeTurner();

export const enum DayPeriods {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export const defaultFormat: DateFormat = {
  order: ['M', 'D', 'Y'],
  separator: '/',
};

/**
 * @returns a value that corresponds to a key in the translation file
 */
export function getCurrentPeriodOfDay(date: Date = new Date()): DayPeriods {
  const currentHour = date.getHours();
  if (currentHour < MORNING_RANGE) return DayPeriods.MORNING;
  if (currentHour < AFTERNOON_RANGE) return DayPeriods.AFTERNOON;
  return DayPeriods.EVENING;
}

const placeholderMap = new Map([
  ['D', 'dd'],
  ['M', 'mm'],
  ['Y', 'yyyy'],
]);

export function formatPlaceholder(format: DateFormat = defaultFormat) {
  return format.order
    .map((key) => placeholderMap.get(key))
    .join(format.separator)
    .toUpperCase();
}

export function formatDate(value?: number, format: DateFormat = defaultFormat) {
  const formatString = formatPlaceholder(format);
  if (!exists(value)) return formatString;
  return dayjs(value).format(formatString);
}

/** Given UTC value of minutes-from-midnight, return the time-of-day string for the current timezone. */
export function formatTime(value?: number) {
  if (!exists(value)) return '';
  try {
    return turner.fromUtcMinutes(value).toTimeString();
  } catch (error) {
    return '';
  }
}

export function getValueFromFormattedDate(formattedDate: string, format: DateFormat = defaultFormat) {
  const parts = formattedDate.split(format.separator);
  if (parts.length !== format.order.length) {
    return null;
  }
  const partMap = format.order.reduce((acc, key, index) => acc.set(key, Number(parts[index])), new Map());
  const date = new Date(partMap.get('Y'), partMap.get('M') - 1, partMap.get('D'));
  return date.getUTCMilliseconds();
}

/** Given a formatted time string, derive the UTC value of minutes-from-midnight. */
export function getValueFromFormattedTime(formattedTime: string) {
  try {
    return turner.fromTimeString(formattedTime).toUtcMinutes();
  } catch (error) {
    return null;
  }
}

/**
 * Formats the duration between two times (provided as Unix timestamps in milliseconds)
 * as a human-readable string.
 * - If the duration includes days, it adds "d" (e.g., "1d").
 * - If the duration includes hours, it adds "h" (e.g., "2h").
 * - Always includes minutes as "m" (e.g., "23m").
 * - If duration is less than a minute return "1m".
 *
 * @param {number} start - The start time as a Unix timestamp in milliseconds.
 * @param {number} end - The end time as a Unix timestamp in milliseconds.
 * @returns {string} - The formatted duration string.
 */
export function formatDuration(start: number, end: number): string {
  if (start > end) {
    throw new Error('Start time cannot be greater than end time');
  }

  // Normalize to the start of the minute, removing seconds
  const normalizedStart = Math.floor(start / 60000) * 60000;
  const normalizedEnd = Math.floor(end / 60000) * 60000;

  const diffInMilliseconds = normalizedEnd - normalizedStart;
  const dur = dayjs.duration(diffInMilliseconds);

  const days = Math.floor(dur.asDays());
  const hours = dur.hours();
  const minutes = dur.minutes();

  const parts: string[] = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  if (days <= 0 && hours <= 0 && minutes <= 0) parts.push('1m');

  return parts.join(' ');
}
