import { Button, Title, Text } from '@mantine/core';
import { Flow } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { toaster } from 'services/toaster';
import { useFlowStore, useSingleInstanceValidator } from 'stores/flow';
import { useInspectionMetaStore } from 'stores/inspectionMeta';
import { Drawer, drawerTestIds } from 'components';
import { OngoingExecutionList } from './OngoingExecutionList';

interface FlowDrawerProps {
  flow?: Flow;
  opened: boolean;
  onClose: () => void;
}

export const testIds = {
  drawer: drawerTestIds,
  title: 'flow-drawer-title',
  subtitle: 'flow-drawer-subtitle',
  startBtn: 'flow-drawer-start-button',
};

export const FlowDrawer = ({ flow, opened, onClose }: FlowDrawerProps) => {
  const { spyRef, spyClick } = useSpy();
  const { t } = useTranslation();
  const navigate = useForceNavigate();
  const isSingleInstanceValid = useSingleInstanceValidator();
  const { loadInspectionMeta } = useInspectionMetaStore(['loadInspectionMeta']);
  const { startExecution } = useFlowStore(['startExecution']);

  const toastSingleInstanceError = (name: string) => {
    toaster.error({
      autoClose: false,
      message: t('flows.errors.singleInstance.message', { name }),
    });
  };

  const toastRequestError = () => toaster.error({ message: t('flows.errors.failedRequest.message') });

  const createExecution = async (flowId: string, version: string) => {
    const execution = await startExecution({
      flowId,
      version,
      preInspectionMetadata: [],
      uniqueIdentifier: '',
    });
    if (execution?.id) {
      navigate(ROUTES.INSPECTION(execution.id));
    } else toastRequestError();
  };

  const handleStartClick = async () => {
    if (!flow) return;

    spyClick(names.FormDrawer.StartNew, flow.id);

    if (!isSingleInstanceValid(flow.id)) {
      toastSingleInstanceError(flow.name);
      return;
    }
    const inspectionMeta = await loadInspectionMeta(flow.id, flow.activeVersion);
    const requiresMetadata = Boolean(inspectionMeta?.preInspectionFields?.length);
    if (requiresMetadata) {
      navigate(ROUTES.PRE_INSPECTION(flow.id));
      return;
    }
    await createExecution(flow.id, flow.activeVersion);
  };

  useSpyOpened(spyRef, names.FormDrawer.self, opened);

  return (
    <Drawer opened={opened} height={640} onClose={onClose}>
      <Drawer.Header withCloseButton />
      <Drawer.Body>
        <Title order={5} ta='left' data-testid={testIds.title}>
          {flow?.name}
        </Title>
        <Text c='cool.5' size='sm' data-testid={testIds.subtitle}>
          {flow?.description}
        </Text>
        {flow && <OngoingExecutionList flowId={flow.id} />}
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleStartClick} data-testid={testIds.startBtn}>
          {t('home.executionsDrawer.startBtn')}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};
