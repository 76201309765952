import { UserIdentity } from '@flow/flow-backend-types';
import { Avatar, Box, Button, Card, CardProps, Group, Text, Flex, Stack } from '@mantine/core';
import { useMemo, ReactNode } from 'react';
import cn from 'classnames';
import { ThemeVariant } from 'interfaces';
import { Debug, UserAvatar, StatusBadge } from 'components';
import { exists } from 'utils';
import classes from './BaseCardLayout.module.css';

interface BaseCardLayoutProps extends CardProps {
  id?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  rightSection?: ReactNode;
  participants?: UserIdentity[];
  status?: string;
  statusVariant?: ThemeVariant;
  metadata?: string;
  variant?: 'default' | 'thematic';
  actionButtonTitle?: string;
  onClick?: () => void;
}

export const MAX_DISPLAYED_AVATARS = 3;
export const testIds = {
  container: 'сard-container',
  title: 'сard-title',
  subtitle: 'сard-subtitle',
  rightSection: 'сard-rightSection',
  metadata: 'сard-metadata',
  actionButton: 'сard-actionButton',
};

export const BaseCardLayout = ({
  id,
  title,
  subtitle,
  rightSection,
  participants = [],
  metadata,
  actionButtonTitle,
  status,
  statusVariant = 'default',
  variant = 'default',
  onClick,
  ...props
}: BaseCardLayoutProps) => {
  const styles = useMemo(() => {
    if (variant !== 'thematic') return undefined;
    return {
      '--circle1-top': `${Math.random() * 135 - 50}%`, // range from -50% to 85%
      '--circle1-left': `${Math.random() * 135 - 50}%`,
      '--circle2-top': `${Math.random() * 135 - 50}%`,
      '--circle2-left': `${Math.random() * 135 - 50}%`,
    };
  }, []);
  const hasFooter = exists(participants.length || metadata || status || actionButtonTitle);
  const avatarSliceLength =
    participants.length > MAX_DISPLAYED_AVATARS ? MAX_DISPLAYED_AVATARS - 1 : MAX_DISPLAYED_AVATARS;
  return (
    <Card
      radius={12}
      shadow='xs'
      withBorder={false}
      style={styles}
      className={cn('select-none', { [classes.thematicBg]: variant === 'thematic' })}
      onClick={onClick}
      data-testid={testIds.container}
      {...props}
    >
      <Debug>
        <Text size='xs' c='amber'>
          ID: {id}
        </Text>
      </Debug>
      <Stack justify='space-between' gap='zero' flex={1} h='100%' className='z-10'>
        {(title || subtitle) && (
          <Flex justify='space-between' align='center' gap='tn' flex={2}>
            <Stack justify='space-between' gap='zero' flex={1} h='100%'>
              {title && <Box data-testid={testIds.title}>{title}</Box>}
              {subtitle && <Box data-testid={testIds.subtitle}>{subtitle}</Box>}
            </Stack>
            {rightSection && <Flex data-testid={testIds.rightSection}>{rightSection}</Flex>}
          </Flex>
        )}
        {hasFooter && (
          <Group justify='space-between' gap='zero' flex={1}>
            <Group gap='tn'>
              {!!participants.length && (
                <Avatar.Group>
                  {participants.slice(0, avatarSliceLength).map((user) => (
                    <UserAvatar
                      user={user}
                      key={user.userId}
                      size={30}
                      variant='filled'
                      className={cn({ 'border-none': variant === 'thematic' })}
                    />
                  ))}
                  {participants.length > MAX_DISPLAYED_AVATARS && (
                    <UserAvatar size={30} variant='filled' className={cn({ 'border-none': variant === 'thematic' })}>
                      +{participants.length - avatarSliceLength}
                    </UserAvatar>
                  )}
                </Avatar.Group>
              )}
              {metadata && (
                <Text size='sm' c={variant === 'thematic' ? 'cool.1' : 'cool.5'} data-testid={testIds.metadata}>
                  {metadata}
                </Text>
              )}
            </Group>
            <Group>
              {status && <StatusBadge variant={statusVariant} text={status} />}
              {actionButtonTitle && (
                <Button size='md' h={32} px='sm' data-testid={testIds.actionButton}>
                  {actionButtonTitle}
                </Button>
              )}
            </Group>
          </Group>
        )}
      </Stack>
    </Card>
  );
};
