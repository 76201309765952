import { Flex, ThemeIcon, rem, Burger, Stack, Text, Transition } from '@mantine/core';
import { names, useSpy } from 'services/espionage';
import { useCurrentUser } from 'stores/auth';
import { useDistraction } from 'stores/app';
import { LogoLoopSingle } from 'assets';
import { useGreeting } from 'hooks';
import { AnimationStage, CONTENT_TRANSITION_DURATION, useHomePageLoad } from './HomePageLoadContext';

export const testIds = {
  menuButton: 'home-page-header-menu-button',
  logo: 'home-page-header-logo',
  greeting: 'home-page-header-greeting',
};

export const HomePageHeader = () => {
  const { spyClick } = useSpy();
  const user = useCurrentUser();
  const { finishAnimationStage: finishAnimatingTitle, animationStage } = useHomePageLoad(AnimationStage.title);
  const { finishAnimationStage: finishAnimatingContent } = useHomePageLoad(AnimationStage.content);
  const animatingTitle = animationStage >= AnimationStage.title;
  const animatingContent = animationStage >= AnimationStage.content;
  const { distracting: appMenuOpened, distract: openAppMenu } = useDistraction('app-menu');
  const greeting = useGreeting();

  const onOpenMenu = () => {
    spyClick(names.HomePage.AppMenu);
    openAppMenu();
  };

  return (
    <Transition
      mounted={animatingTitle}
      transition='fade'
      enterDelay={250}
      duration={2000}
      onEntered={finishAnimatingTitle}
    >
      {(style) => (
        <Flex
          style={style}
          component='header'
          w='100%'
          pos='absolute'
          className='transition-all'
          justify='center'
          pt='tn'
        >
          <Transition mounted={animatingContent} transition='slide-right' duration={CONTENT_TRANSITION_DURATION}>
            {(logoStyle) => (
              <ThemeIcon
                style={logoStyle}
                pos='absolute'
                c='emerald.9'
                left={rem(16)}
                top={rem(8)}
                variant='transparent'
                size={36}
                data-testid={testIds.logo}
              >
                <LogoLoopSingle />
              </ThemeIcon>
            )}
          </Transition>
          <Transition mounted={animatingContent} transition='slide-left' duration={CONTENT_TRANSITION_DURATION}>
            {(burgerStyle) => (
              <Burger
                style={burgerStyle}
                pos='absolute'
                right={rem(16)}
                top={rem(8)}
                size='sm'
                opened={appMenuOpened}
                onClick={onOpenMenu}
                data-testid={testIds.menuButton}
              />
            )}
          </Transition>
          <Stack
            gap='zero'
            align='center'
            className='transition-[margin]'
            style={{ transitionDuration: `${CONTENT_TRANSITION_DURATION}ms` }}
            mt={animatingContent ? 0 : '45dvh'}
            data-testid={testIds.greeting}
            onTransitionEnd={finishAnimatingContent}
          >
            <Text fw={400} fz='sm'>
              {greeting}
            </Text>
            <Text fw={600} fz='lg'>
              {user?.givenName}
            </Text>
          </Stack>
        </Flex>
      )}
    </Transition>
  );
};
