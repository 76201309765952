import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Text, ThemeIcon } from '@mantine/core';
import { useRef } from 'react';
import { Drawer, testIds as drawerTestIds } from 'components/Drawer/Drawer';
import { HistoryFilterGroupKey, HistoryFilterValues } from 'stores/flow';
import { HISTORY_FILTER_DEFAULT_VALUE } from 'consts';
import { Info } from '@phosphor-icons/react';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { HistoryFilterGroup, testIds as filterGroupTestIds } from './components/HistoryFilterGroup/HistoryFilterGroup';
import { FILTER_GROUPS } from './filter.const';

interface HistoryFilterDrawerProps {
  opened: boolean;
  filterValues: HistoryFilterValues;
  filteredCount: number;
  onClose: () => void;
  onFilterChange: (key: HistoryFilterGroupKey, values: string[]) => void;
  onFiltersApply: (values: HistoryFilterValues) => void;
}

export const testIds = {
  header: 'history-filter-header',
  resetBtn: 'history-filter-reset',
  applyBtn: 'history-filter-apply',
  drawer: drawerTestIds,
  filterGroup: filterGroupTestIds,
};

const defaultFilterValues: HistoryFilterValues = {
  statuses: [HISTORY_FILTER_DEFAULT_VALUE],
  periods: [HISTORY_FILTER_DEFAULT_VALUE],
  executedBy: [HISTORY_FILTER_DEFAULT_VALUE],
};

export const HistoryFilterDrawer = ({
  opened,
  filterValues,
  filteredCount,
  onClose,
  onFilterChange,
  onFiltersApply,
}: HistoryFilterDrawerProps) => {
  const { t } = useTranslation();
  const { spyRef, spyClick } = useSpy();
  const prevFilterValues = useRef(defaultFilterValues);
  const filtersApplied = Object.values(filterValues).some((values) => !values.includes(HISTORY_FILTER_DEFAULT_VALUE));

  const onApply = () => {
    prevFilterValues.current = filterValues;
    spyClick(names.FormFilterDrawer.Submit, {
      filters: filterValues,
      shownResults: filteredCount,
    });
    onClose();
  };

  const onReset = () => {
    prevFilterValues.current = defaultFilterValues;
    onFiltersApply(defaultFilterValues);
    spyClick(names.FormFilterDrawer.Reset);
  };

  const handleClose = () => {
    onFiltersApply(prevFilterValues.current);
    spyClick(names.FormFilterDrawer.Close);
    onClose();
  };

  const computeNewFilterValues = (values: string[]) => {
    const hasMultipleFilters = values.length > 1;
    const shouldUnselectAllFilter = hasMultipleFilters && values[0] === HISTORY_FILTER_DEFAULT_VALUE;
    const shouldUnselectOtherFilters = hasMultipleFilters && values[values.length - 1] === HISTORY_FILTER_DEFAULT_VALUE;

    return values.length === 0
      ? [HISTORY_FILTER_DEFAULT_VALUE]
      : values.filter((value) => {
          if (shouldUnselectAllFilter) return value !== HISTORY_FILTER_DEFAULT_VALUE;
          if (shouldUnselectOtherFilters) return value === HISTORY_FILTER_DEFAULT_VALUE;
          return true;
        });
  };

  const handleFilterChange = (key: HistoryFilterGroupKey) => (values: string[]) => {
    const hasNoValuesChanged = values.length === 0 && filterValues[key][0] === HISTORY_FILTER_DEFAULT_VALUE;
    if (hasNoValuesChanged) return;
    const newValues = computeNewFilterValues(values);

    onFilterChange(key, newValues);
    spyClick(names.FormFilterDrawer.Select, {
      filterName: key,
      filterValues: newValues,
    });
  };

  useSpyOpened(spyRef, names.FormFilterDrawer.self, opened);

  return (
    <Drawer height='90%' opened={opened} onClose={handleClose}>
      <Drawer.Header
        leftSection={
          <Button
            variant='subtle'
            size='compact-md'
            h='100%'
            py='sm'
            px='xs'
            bg='transparent'
            color='emerald.6'
            disabled={!filtersApplied}
            onClick={onReset}
            data-testid={testIds.resetBtn}
          >
            {t('home.filters.reset')}
          </Button>
        }
        withCloseButton
        data-testid={testIds.header}
      >
        {t('home.filters.title')}
      </Drawer.Header>
      <Drawer.Body>
        <Flex>
          <ThemeIcon variant='transparent' color='cool.4'>
            <Info size={20} weight='fill' />
          </ThemeIcon>
          <Text fz='sm' c='cool.5' pt={4}>
            {t('home.filters.disclaimer')}
          </Text>
        </Flex>
        <Box px='tn'>
          {FILTER_GROUPS.map(({ type, filters }) => (
            <HistoryFilterGroup
              key={type}
              type={type}
              filters={filters}
              values={filterValues[type]}
              onFilterChange={handleFilterChange(type)}
            />
          ))}
        </Box>
      </Drawer.Body>
      <Drawer.Footer>
        <Button size='lg' onClick={onApply} data-testid={testIds.applyBtn}>
          {t('home.filters.applyBtn', { count: filteredCount })}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};
